<template>
  <div class="audio__wrapper">
    <div @click="togglePlay" class="audio__button" role="button">
      <div class="audio__container">
        <play-icon class="audio__container--icon" v-if="!isPlaying"/>
        <pause-icon class="audio__container--icon" v-else/>
        <span class="audio__container--copy">{{ timestampText }}</span>
      </div>
    </div>
    <div class="audio__overlay">
      <div
        :style="styleProgress"
        class="audio__progress"></div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler';
import {
  AUDIO_PAUSE_ALL,
  AUDIO_STOP_ALL,
} from '@/eventTypes';
import EventBus from '@/eventBus';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    ext: String,
  },
  data() {
    return {
      seekInterval: null,
      isPlaying: false,
      sound: null,
      duration: 0,
      seek: 0,
    };
  },
  computed: {
    durationText() {
      return this.formatSeconds(this.duration);
    },
    seekText() {
      return this.formatSeconds(this.seek);
    },
    timestampText() {
      return this.isPlaying ? this.seekText : this.durationText;
    },
    progressPercentage() {
      return this.calcPercentage(this.seek, this.duration);
    },
    styleProgress() {
      return {
        width: `${this.progressPercentage}%`,
      };
    },
  },
  mounted() {
    this.resetFlags();
    this.sound = new Howl({
      html5: true,
      src: [this.src],
      format: [this.ext],
    });

    this.sound.on('load', () => {
      this.duration = this.sound.duration();
    });

    this.sound.on('play', () => {
      this.isPlaying = true;
    });

    this.sound.on('end', () => {
      this.resetFlags();
    });

    this.sound.on('pause', () => {
      this.isPlaying = false;
    });

    this.sound.on('stop', () => {
      this.resetFlags();
    });

    EventBus.$on(AUDIO_PAUSE_ALL, () => {
      if (this.sound && this.sound.playing()) {
        this.sound.pause();
      }
    });

    EventBus.$on(AUDIO_STOP_ALL, () => {
      if (this.sound && this.sound.playing()) {
        this.sound.stop();
      }
    });
  },
  methods: {
    resetFlags() {
      this.seek = 0;
      this.isPlaying = false;
    },
    formatSeconds(seconds) {
      const quotient = Math.floor(seconds / 60);
      const remainder = Math.floor(seconds % 60);

      return `${quotient}:${remainder.toString().padStart(2, '0')}`;
    },
    togglePlay() {
      if (this.sound.playing()) {
        this.sound.pause();
        this.stopPlayback();
      } else {
        this.pauseAudio();
        this.sound.play();
        this.startPlayback();
      }
    },
    calcPercentage(startTime, duration) {
      if (duration > 0) {
        return (startTime * 100) / duration;
      }

      return 0;
    },
    startPlayback() {
      this.seekInterval = setInterval(() => {
        this.seek = this.sound.seek();
      }, 100);
    },
    stopPlayback() {
      clearInterval(this.seekInterval);
    },
    pauseAudio() {
      EventBus.$emit(AUDIO_PAUSE_ALL);
    },
  },
};
</script>
